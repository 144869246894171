// styles
import '@scss/app.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-lightbox/dist/slick-lightbox.css'
import 'plyr/dist/plyr.css'
import 'swiper/css'
import 'flickity/css/flickity.css'
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css'

import $ from 'jquery'

window.$ = $
window.jQuery = $

import('@js/custom/main.js')

// required for HMR to work
if (import.meta.hot) {
    import.meta.hot.accept()
}
